import { CSSProperties } from "react";
import { useSmartState } from "../../../hooks/use_smart_state";
import { Button,AlternateButton } from "./button";
import { useForm } from "./form";
import { useCssStateManager } from "../../../hooks/use_css_state_manager";

export type SubmitButtonProps = {
    caption: string;
    disableIfUnchanged?: string[];
    disableIfOtpNotComplete?: string;
    name?: string;
    inline?: boolean;
    alternate?: boolean;
    fixedWidth?: number;
};

export default function SubmitButton({caption,disableIfUnchanged = [],disableIfOtpNotComplete = "",name,inline = false,alternate = false,fixedWidth}:SubmitButtonProps) {
    const { useSubmit,submit,align } = useForm();

    const cssStateManager = useCssStateManager(["button-wrapper"])
    cssStateManager.useProperty(inline,"is-inline");

    const disabled = useSmartState(false);

    useSubmit(() => ({
        getDisabledState() {
            return disabled;
        },
        getDisableIfUnchanged() {
            return disableIfUnchanged;
        },
        getDisableIfOtpNotComplete() {
            return disableIfOtpNotComplete;
        }
    }),name);

    const styles: CSSProperties = {
        textAlign: align
    };    

    const SelectedButton = alternate?AlternateButton:Button;

    return (
        <>
            <div className={cssStateManager.getClassNames()} style={styles}>
                <SelectedButton caption={caption} disabled={disabled.value} onClick={() => submit(name)} fixedWidth={fixedWidth} />
            </div>
            <style jsx>{`
            .button-wrapper {

            }

            .is-inline {
                display: inline-block;
            }
            `}</style>
        </>
    );
}