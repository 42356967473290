import { useContext } from "react";
import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { LayoutStateContext } from "../../../contexts/layout_state";

export type BoxProps = {
    border?: boolean;
    desktopWidth?: number;
    children: any;
};

export default function Box({children,border,desktopWidth = 600}:BoxProps) {
    const cssStateManager = useCssStateManager(["box"]);
    cssStateManager.useProperty(border,"border");

    const { mobileDetect: { widthCutoffStyleSection } } = useContext(LayoutStateContext);

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="inner">
                    {children}
                </div>
            </div>
            <style jsx>{`
            .box {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            .inner {
                max-width: ${desktopWidth}rem;
                min-width: ${desktopWidth}rem;
            }

            .border .inner {
                border: 1rem solid var(--border-color);
                padding: 20rem;
            }

            ${widthCutoffStyleSection(`
                .box .inner {
                    max-width: none;
                    min-width: 0rem;
                    width: 100%;
                }
            `)}
            `}</style>
        </>
    );
}