import { CSSProperties, useContext, useEffect, useRef } from "react";
import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { useForm } from "./form";
import { misc } from "../../../lib/misc";
import { SmartState, useSmartState } from "../../../hooks/use_smart_state";
import { SelectOption } from "../../../lib/shared/types/form.types";
import { LayoutStateContext } from "../../../contexts/layout_state";
import { EventPane } from "../../core/event_pane";

export type InputProps = {
    name: string;
    caption?: string;
    placeholder?: string;
    type?: "text" | "password" | "mobile" | "hidden" | "otp" | "select" | "checkbox";
    initialValue?: string;
    initialSelectedIndex?: number;
    otpLength?: number;
    selectOptions?: SelectOption[];
    onChange?: (value: string) => void;
    onKeyDown?: (key: string,preventDefault?: () => void,preventEnter?: () => void) => void;
    onBlur?: () => void;
    callOnChangeOnInit?: boolean;
    required?: boolean;
};

export default function Input({
        type = "text",
        name,
        initialValue = "",
        otpLength = 6,
        selectOptions = [],
        onChange,
        callOnChangeOnInit = false,
        initialSelectedIndex,
        caption,
        placeholder,
        required,
        onKeyDown = () => {},
        onBlur = () => {}
    }:InputProps) {
    const { scalePixelToRem } = useContext(LayoutStateContext);
    const cssStateManager = useCssStateManager(["input"]);
    const error = cssStateManager.useState(false,"error");
    const disabled = cssStateManager.useState(false,"disabled");
    cssStateManager.useProperty(type==="mobile","type-is-mobile");
    cssStateManager.useProperty(type==="checkbox","type-is-checkbox");
    cssStateManager.useProperty(type==="hidden","type-is-hidden");
    cssStateManager.useProperty(type==="otp","type-is-otp");
    const hasFocus = cssStateManager.useState(false,"has-focus");

    const mobileCountryCode = useSmartState("");

    const lastDiffersFromInitialValueRef = useRef(false);

    const inputRef = useRef<HTMLInputElement>();    
    const selectRef = useRef<HTMLSelectElement>();

    const { input: { maxWidth,captionPosition = "header" },useInput,align} = useForm();

    const getValue = () => {
        if (type==="select") {
            return misc.getSelectElementValue(selectRef);
        }
        else if (type==="checkbox") {
            return misc.getInputElementChecked(inputRef)?"checked":"";
        }
        else {
            const value = misc.getInputElementValue(inputRef);
            if (type==="mobile") {                
                return `${mobileCountryCode.value}/${value}`;
            }
            else {
                return value;
            }
        }
    };

    const setValue = (value: string) => {
        if (type==="select") {            
            misc.setSelectElementValue(selectRef,value);
        }
        else if (type==="checkbox") {
            misc.setInputElementChecked(inputRef,value==="checked");
        }
        else {
            if (type==="mobile") {
                const parts = (value.includes("/")?value:`/${value}`).split("/");
                const countryCode = parts[0] || "";
                const number = parts.slice(1).join("/").trim();
                mobileCountryCode.value = countryCode;
                misc.setInputElementValue(inputRef,number);    
            }
            else {
                misc.setInputElementValue(inputRef,value);    
            }
        }
    };

    const getDiffersFromInitialValue = () => initialValue!==getValue();

    const interfaceToForm = useInput(() => ({
        focus() {
            if (type==="select") {
                misc.focusSelectElement(selectRef);
            }
            else {
                misc.focusInputElement(inputRef);
            }            
        },
        getName() {
            return name;
        },
        signalInitialized() {
            if ((callOnChangeOnInit) && (onChange)) {
                onChange(getValue());
            }
        },
        getValue,
        setValue,
        getErrorState() {
            return error;
        },
        getInitialValue() {
            return initialValue;
        },
        getType() {
            return type;
        },
        setSelectedIndex(value: number) {
            if (type==="select") {
                misc.setSelectElementSelectedIndex(selectRef,value);
            }
        },
        getInitialSelectedIndex() {
            if ((type==="select") && (typeof initialSelectedIndex==="number") && (initialSelectedIndex>=0)) {
                return initialSelectedIndex;
            }
            else {
                return -1;
            }            
        },
        getIsHidden() {
            return type==="hidden";
        },
        getDisabledState() {
            return disabled;
        },
        getDiffersFromInitialValue,
        getOtpIsComplete() {
            return (type==="otp")?getValue().length===otpLength:false;
        }
    }));

    const { makeup: { caption: makeupCaption,required: makeupRequired,placeholder: makeupPlaceholder } } = interfaceToForm;    

    caption = caption || makeupCaption;
    placeholder = placeholder || makeupPlaceholder || "";
    if (typeof required!=="boolean") required = makeupRequired;

    const handleKeyDown = (key: string,preventDefault: () => void) => {
        let mustPreventEnter = false;
        const preventEnter = () => {
            mustPreventEnter = true;
        };
        onKeyDown(key,preventDefault,preventEnter);
        if ((key==="Enter") && (!mustPreventEnter)) {
            interfaceToForm.signalKeyDownEnter();
        }        
        if ((key==="Backspace") && (type==="mobile")) {
            const selectionStart = misc.getInputElementSelectionStart(inputRef);
            if (selectionStart===0) {
                mobileCountryCode.value = "";
            }
        }
    };

    const handleChange = () => {
        if (type==="otp") {
            setValue(getValue().split("").filter(x => /\d/.test(x)).filter((_,index) => index<otpLength).join(""));
        }
        interfaceToForm.signalChange();
        const newValue = getDiffersFromInitialValue();
        if (newValue!==lastDiffersFromInitialValueRef.current) {
            lastDiffersFromInitialValueRef.current = newValue;
            interfaceToForm.signalDiffersFromInitialValueChange();
        }
        if (onChange) onChange(getValue());
    };

    const handleFocus = () => {
        hasFocus.value = true;
    };
    const handleBlur = () => {
        hasFocus.value = false;
        onBlur();
    };

    const styles: CSSProperties = {
        textAlign: align
    };

    const innerStyles: CSSProperties = {
        ...((typeof maxWidth==="number")?{maxWidth}:{})
    };

    const mobilePlusRef = useRef<HTMLDivElement>();
    const mobileInputPaddingLeft = useSmartState(12);

    const typeMap = {
        text: "text",
        password: "password",
        mobile: "text",
        hidden: "hidden",
        otp: "text",
        checkbox: "checkbox"
    };

    useEffect(() => {
        const { toCallOnFirstEffect } = interfaceToForm;
        if (toCallOnFirstEffect) toCallOnFirstEffect();
    },[]);

    useEffect(() => {
        if ((type==="mobile") && (mobilePlusRef.current)) {
            mobileInputPaddingLeft.value = scalePixelToRem(mobilePlusRef.current.offsetWidth)+17;
        }
    },[mobileCountryCode.value]);

    const inputStyles: CSSProperties = {
        ...((type==="mobile")?{paddingLeft: `${mobileInputPaddingLeft.value}rem`}:{})
    };

    const handleCheckboxLabelClicked = () => {
        misc.setInputElementChecked(inputRef,!misc.getInputElementChecked(inputRef));
        handleChange();
        misc.focusInputElement(inputRef);
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()} style={styles}>
                <div className="inner" style={innerStyles}>
                    {(type==="select")?(
                        <select 
                            ref={selectRef} 
                            onChange={handleChange}
                            disabled={disabled.value}
                            onKeyDown={(e) => handleKeyDown(e.key,() => e.preventDefault())}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            >
                            {selectOptions.map(({caption,value,disabled = false},index) => <option key={`${value}-${index}`} value={value} {...(disabled?{disabled: true}:{})}>{caption}</option>)}
                        </select>
                    ):(
                        <input 
                            ref={inputRef}
                            type={typeMap[type]} 
                            {...(captionPosition==="placeholder")?{placeholder: caption+(!required?" (optional)":"")}:{placeholder}}
                            onKeyDown={(e) => handleKeyDown(e.key,() => e.preventDefault())}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            disabled={disabled.value}
                            style={inputStyles}
                        />
                    )}
                    {(type==="mobile")?(
                        <div className="mobile-plus " ref={mobilePlusRef}>+ {(mobileCountryCode.value!=="")?<span className="country-code">{mobileCountryCode.value}</span>:""}</div>
                    ):""}
                    <div className="border"></div>
                    {((captionPosition==="header") && (type!=="hidden") && (type!=="otp"))?(
                        <div className="caption-back">
                            <div className="caption">
                                {caption}{!required?<span className="caption-optional">&nbsp;(optional)</span>:""}
                                {(type==="checkbox")?(
                                    <EventPane onClick={handleCheckboxLabelClicked} />
                                ):""}
                            </div>
                        </div>
                    ):""}
                    {((type==="otp"))?(
                        <div className="otp-bars">
                            {"_".repeat(otpLength)}
                        </div>
                    ):""}                    
                </div>
            </div>
            <style jsx>{`
            .inner {
                display: inline-block;
                width: 100%;
                position: relative;      
                --border-radius: 3rem;  
            }
            .input {
                margin-bottom: 20rem;                
            }

            .country-code {
                color: #fff;
                background-color: #9999b2;
                padding: 2rem 7rem;
                border-radius: 3rem;
                font-size: 12rem;
                position: relative;
                top: -1rem;
            }

            .border {
                position: absolute;
                --overlap: -1rem;
                --overlap-verti: var(--overlap);
                --overlap-hori: var(--overlap);
                left: var(--overlap-hori);
                top: var(--overlap-verti);
                right: var(--overlap-hori);
                bottom: var(--overlap-verti);
                pointer-events: none;
                border-radius: var(--border-radius);
                display: none;
            }

            .type-is-checkbox .border {
                --overlap-verti: -7rem;
                --overlap-hori: -1rem;
                transform: translateY(-1rem);
                display: none;
            }

            .type-is-hidden {
                margin-bottom: 0rem;
                height: 0rem;
                overflow: hidden;
            }

            .caption-back {
                position: absolute;
                left: 10rem;
                top: -1rem;                
                padding: 0rem 4rem;
                border-top: 2rem solid var(--input-backcolor);      
                pointer-events: none;          
            }

            .caption-optional {
                color: #aaa;
            }

            .caption {
                position: relative;
                top: -10rem;
                font-size: 11rem;
                color: var(--input-textcolor);                                
                pointer-events: none;          
            }

            .type-is-checkbox .caption-back {
                border-top: none;
                pointer-events: auto;

            }

            .type-is-checkbox .caption {
                top: 3rem;
                left: 10rem;
                visibility: visible;
                pointer-events: auto;
            }
            .type-is-checkbox .caption-back {
                visibility: hidden;
            }

            .type-is-checkbox .caption-optional {
                display: none;
            }

            input,select {
                background-color: var(--input-backcolor);
                border: 1rem solid var(--input-bordercolor);
                border-radius: var(--border-radius);
                padding: 12rem 10rem;
                width: 100%;                
                outline: none;
                color: var(--input-textcolor);
                font-size: 14rem;
                font-family: Arial;
            }

            input[type="checkbox"] {
                width: auto;
            }

            .otp-bars {
                position: absolute;
                top: 18rem;
                left: 10rem;
                font-size: 24rem;
                letter-spacing: 10rem;
                pointer-events: none;
                font-family: Arial;
                color: var(--input-textcolor);
                opacity: 0.8;
            }

            /*
            .type-is-mobile input {
                padding-left: 22rem;
            }
            */

            .type-is-otp input {
                border: none;
                background-color: transparent;
                font-size: 24rem;
                letter-spacing: 10rem;
            }

            .disabled input,.disabled select {
                background-color: var(--input-disabled-backcolor);
            }

            input::placeholder,select::placeholder {
                color: var(--input-placeholder-textcolor);
            }
            
            .has-focus .border {
                border: 2rem solid var(--input-focus-bordercolor);
                display: block;
            }

            .type-is-checkbox .border {
                display: none;
            }

            .type-is-checkbox.has-focus .caption {
                color: var(--input-focus-bordercolor);
            }

            .error input,.error select {
                color: var(--error-color);
            }

            .error .border {
                border: 2rem solid var(--error-color);
                display: block;
            }

            .error .caption {
                color: var(--error-color);
                font-weight: 600;
            }

            .mobile-plus {
                position: absolute;
                left: 12rem;
                top: 10rem;
                font-size: 15rem;
                color: var(--input-textcolor);
            }

            `}</style>
        </>
    );
}