import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { useCustomRouter } from "../../../hooks/use_custom_router";
import { EventPane } from "../../core/event_pane";
import MobileScope from "../mobile-scope";
import { useForm } from "./form";

export type TitleProps = {
    children: any;
};

export default function Title({children}:TitleProps) {
    const cssStateManager = useCssStateManager(["title"]);
    const router = useCustomRouter();

    const { renderParentNav } = useForm();

    const genHandleBackClick = (path: string) => {
        return () => {
            router.push(path);
        };
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                {children}
                {renderParentNav(({path}) => (
                    <MobileScope>
                        <div className="back-button">
                            <i className="icon-navbar-left-arrow" />
                            <EventPane onClick={genHandleBackClick(path)} />
                        </div>
                    </MobileScope>
                ))}                
            </div>
            <style jsx>{`
            .title {
                color: var(--text-color);
                text-align: center;
                font-size: 22rem;
                font-weight: 600;
                position: relative;
                margin-bottom: 25rem;
            }

            .back-button {
                --size: 40rem;
                position: absolute;
                left: -10rem;
                top: -5rem;
                width: var(--size);
                height: var(--size);                    
                display: flex;
                justify-content: center;
                align-items: center;                    
            }

            .back-button i {
                display: block;
                font-size: 20rem;
                color: #777;
            }

            .back-button.touchdown {
                background-color: rgba(0,0,0,0.07);
            }

            `}</style>
        </>
    );
}