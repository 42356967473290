import { styledButton } from "../button";

export const Button = styledButton({display: "inline-block",fontSize: "14rem"},{},{
    backgroundColorCssVarName: "--button-backcolor",
    colorCssVarName: "--button-textcolor"
});

export const AlternateButton = styledButton({display: "inline-block",fontSize: "14rem"},{},{
    backgroundColorCssVarName: "--button-alternate-backcolor",
    colorCssVarName: "--button-alternate-textcolor"
})

