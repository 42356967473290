import { CSSProperties } from "react";
import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { useForm } from "./form";

export type PProps = {
    children: any;
    small?: boolean;
    pale?: boolean;
};

export default function P({children,small = false,pale = false}:PProps) {
    const cssStateManager = useCssStateManager(["p"]);

    const { align } = useForm();

    const styles: CSSProperties = { 
        textAlign: align,
        ...(small?{fontSize: "13rem"}:{}),
        ...(pale?{color: "#666"}:{})
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()} style={styles}>{children}</div>
            <style jsx>{`
            .p {
                font-size: 15rem;
                color: var(--text-color);
                margin-bottom: 20rem;
            }
            `}</style>
        </>
    );
}